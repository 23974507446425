.page-heading {
  font-family: "Noto Sans Devanagari";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 37px;
  /* identical to box height */

  letter-spacing: -0.04em;

  color: #454545;
}

.quiz-grid-container {
  margin: auto;
  padding: 30px 0px;
}

.quiz-title {
  /* font-family: 'Noto Sans Devanagari'; */
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 29px;
  /* identical to box height */

  letter-spacing: -0.04em;

  color: #fb5259;
}

.mainDiv { 
  height: 1rem;
  width: 95%;
  background-color: whitesmoke;
  border-radius: 12px;
  margin: 1rem;
  display: none;
}
.childDiv {
  height: 100%;
  background-color: red;
  border-radius: 12px;
  text-align: left;
}
.childDiv span{
  display: inline-block;
  position: absolute;
  right: 25px;
  /* bottom: 10px; */
}

.editor-width{
  width: 100%;
}

.accordian{
  margin: 25px;
  border-radius: 15px;

}

.inside_accordian{
  background: #fff1f3;
  border-radius: 15px;
}

.small_box{
  padding: 5px;
}