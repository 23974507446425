.main-body{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
    height: 100vh;
}

.inner-body{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 700px;
    width: 700px;
}