.wrapper {
    /* border: 3px solid blue; */

}

.container {
    border-radius: 10px;
    background-color: #EA506B;

}

.barCompleted {
    background-color: blue;
    /* width: 100%; */
}

.label {
    font-size: 20px;
    /* color: white; */
    color: red;
}