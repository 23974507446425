.page-heading {
    font-family: "Noto Sans Devanagari";
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 37px;
    /* identical to box height */
  
    letter-spacing: -0.04em;
  
    color: #454545;
    margin-bottom: 5px;
  }  

  .assign_dialog .css-nznsqf-MuiPaper-root-MuiDialog-paper{
    width: 80% !important;
    }


    .mainDiv { 
      height: 1rem;
      width: 95%;
      background-color: whitesmoke;
      border-radius: 12px;
      margin: 1rem;
      display: none;
    }
    .childDiv {
      height: 100%;
      background-color: red;
      border-radius: 12px;
      text-align: left;
    }
    .childDiv span{
      display: inline-block;
      position: absolute;
      right: 25px;
      /* bottom: 10px; */
    }