#myMessage {
    font-weight: 700;
    color: green;

}

#channelMsg {
    font-weight: 700;
    color: black;

}


#bot-msg-cont {
    /* background-color: lightgray; */
    border-radius: 10px;
    color: black;
    padding: 8px;
    font-size: 10px;
    font-weight: 700;
    word-break: break-all;
    margin-bottom: 10px;
}

#my-msg-cont {
    background-color: #d3d3d366;
    border-radius: 10px;
    color: black;
    padding: 8px;
    font-weight: 500;
    /* font-size: 10px; */
    /* font-weight: 700; */
    word-break: break-all;
    margin-bottom: 10px;
    font-size: 12px;
    /* width: max-content; */
}


#channel-msg-cont {
    background-color: #d3d3d366;
    border-radius: 10px;
    color: black;
    padding: 8px;
    /* font-size: 10px; */
    /* font-weight: 700; */
    word-break: break-all;
    margin-bottom: 10px;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    transition: all 0.3s;
}



#log div {
    word-break: break-all;
    /* overflow-y: auto; */
}

/* .msgContainer {
    /* width: 90%; */
/* height: 330px; */
/* overflow-y: scroll; */
/* overflow-x: hidden; */
/* }  */
#log_cont {
    /* height: 100%; */
    /* background-color: red; */
}

#log {
    /* overflow: scroll; */
    /* background-color: red; */

}

.rootc {
    min-height: 100%;

    width: 100%;
    overflow: auto;
}