.BatchScroll {
    overflow: auto;
    flex-wrap: nowrap;

}

.tableScroll {
    overflow: auto;
    flex-wrap: nowrap;
    max-height: 80%;
    word-break: break-all;
    margin-bottom: 10px;

}

.button1{
border-radius: 35px;
font-size: 18px;
padding-top: 7px;
padding-bottom: 7px;
}

.button2{
    border-radius: 35px;
    font-size: 15px;
    padding: 5px 15px;
}

.assign_dialog .css-nznsqf-MuiPaper-root-MuiDialog-paper{
width: 80% !important;
}