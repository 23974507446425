.rootc {
    height: 300px;
    width: 100%;

}

.rootcc {
    height: 75%;
    width: 100%;
    overflow: auto;
}

* {
    -ms-overflow-style: none;
}


::-webkit-scrollbar {
    display: none;
}