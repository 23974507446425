.TeacherScroll {
    overflow: auto;
    flex-wrap: nowrap;
}

.SubjectScroll {
    overflow: auto;
    flex-wrap: nowrap;
}

#capsule {
    height: 100px;
    background: #000;
    border-radius: 10px;
    -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
}