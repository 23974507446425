.page-heading {
    font-family: "Noto Sans Devanagari";
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 37px;
    /* identical to box height */
  
    letter-spacing: -0.04em;
  
    color: #454545;
    margin-bottom: 5px;
  }  

.button-align{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    width: 25%;
    /* margin: 2rem; */
}

.flip-card {
    background-color: transparent;
    /* width: 250px;
    height: 200px; */
    width: 100%;
    height: 200px;
    /* perspective: 1000px; */

}

.flip-card-inner {
    position: relative;
    width: 90%;
    height: 90%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 44px;

}

.flip-card-inner-add {
    position: relative;
    width: 90%;
    height: 90%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 44px;

}

.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

}

.flip-card-front-add {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

}

.flip-card-inner.is-flipped {
    transform: translateX(-100%) rotateY(-180deg);
}

.flip-card-front {

    color: black;
    background: linear-gradient(298.45deg, #FFFFFF 3.1%, rgba(255, 255, 255, 0.69) 95.71%);
    border-radius: 44px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 20px 0;

}

.flip-card-front-add {

    color: black;
    background: linear-gradient(298.45deg, #FFFFFF 3.1%, rgba(255, 255, 255, 0.69) 95.71%);
    border-radius: 44px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 20px 0;


}

.icon-div {
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

}

.heading-div {
    flex: 1;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    color: #E15B73;
}

.flip-card-back {

    color: white;
    transform: rotateY(180deg);
    background: linear-gradient(298.45deg, #E15B73 3.1%, rgba(226, 38, 72, 0.69) 95.71%);
    border-radius: 44px;
}

/* Add Batch */
.rti--container {
    width: 100% !important;
    min-height: 55px;
    background-color: transparent;

}


.rti--input {
    width: 100% !important;


}

.batch_desc{
    margin: 5px 10px;
}